// Entry point for the build script in your package.json
import "./dashboard";
//import "./Chart-2.8.0.min";
//import "./chart.sample";
import "@hotwired/turbo-rails";
//Turbo.session.drive = false
import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min.js';
bulmaCalendar.attach('[type="date"]', options);
import "trix"
import "@rails/actiontext"
